<template functional>
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="160" height="160" rx="80" fill="#F1FAF0" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="160"
      height="160"
    >
      <rect width="160" height="160" rx="80" fill="#F5F6F9" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M37.6514 32.7919L79.4496 32.6895"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M149.242 107.821V45.2578H62.4789V64.0433M-1.79102 119.525H163.709"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M79.6315 45.258V27H37.543V56.9247"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M149.242 55.2891H62.123"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M69.8848 51.738C70.6927 51.738 71.3477 51.0833 71.3477 50.2757C71.3477 49.4681 70.6927 48.8135 69.8848 48.8135C69.0768 48.8135 68.4219 49.4681 68.4219 50.2757C68.4219 51.0833 69.0768 51.738 69.8848 51.738Z"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M76.2315 51.738C77.0394 51.738 77.6944 51.0833 77.6944 50.2757C77.6944 49.4681 77.0394 48.8135 76.2315 48.8135C75.4235 48.8135 74.7686 49.4681 74.7686 50.2757C74.7686 51.0833 75.4235 51.738 76.2315 51.738Z"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M82.5791 51.738C83.3871 51.738 84.042 51.0833 84.042 50.2757C84.042 49.4681 83.3871 48.8135 82.5791 48.8135C81.7712 48.8135 81.1162 49.4681 81.1162 50.2757C81.1162 51.0833 81.7712 51.738 82.5791 51.738Z"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M124.201 89.7679H88.0837C86.3305 89.7679 84.9131 88.3455 84.9131 86.5988C84.9131 84.8464 86.3362 83.4297 88.0837 83.4297H124.201C125.954 83.4297 127.372 84.8521 127.372 86.5988C127.372 88.3512 125.949 89.7679 124.201 89.7679Z"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M114.103 50.7432H138.995"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.421 57.6966H30.2993C28.4355 57.6966 26.9247 59.212 26.9247 61.0814V111.853C26.9247 113.723 28.4355 115.238 30.2993 115.238H58.421C60.2847 115.238 61.7956 113.723 61.7956 111.853V61.0814C61.7956 59.212 60.2847 57.6966 58.421 57.6966ZM30.2993 56.5684C27.8143 56.5684 25.7998 58.5889 25.7998 61.0814V111.853C25.7998 114.346 27.8143 116.366 30.2993 116.366H58.421C60.906 116.366 62.9205 114.346 62.9205 111.853V61.0814C62.9205 58.5889 60.906 56.5684 58.421 56.5684H30.2993Z"
        fill="#001965"
      />
      <circle cx="44.5" cy="110.5" r="1" fill="#001965" stroke="#001965" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.4229 62.1188C40.4229 61.8083 40.6747 61.5566 40.9853 61.5566H49.9842C50.2949 61.5566 50.5467 61.8083 50.5467 62.1188C50.5467 62.4293 50.2949 62.681 49.9842 62.681H40.9853C40.6747 62.681 40.4229 62.4293 40.4229 62.1188Z"
        fill="#001965"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.2988 62.1195C39.2988 62.4299 39.047 62.6816 38.7364 62.6816L37.6115 62.6816C37.3009 62.6816 37.0491 62.4299 37.0491 62.1195C37.0491 61.809 37.3009 61.5573 37.6115 61.5573L38.7364 61.5573C39.047 61.5573 39.2988 61.809 39.2988 62.1195Z"
        fill="#001965"
      />
      <line x1="63" y1="106.5" x2="26" y2="106.5" stroke="#001965" />
      <line x1="63" y1="67.5" x2="26" y2="67.5" stroke="#001965" />
      <path
        d="M35.7715 88.6031L40.7569 93.5863L55.0011 79.3486"
        stroke="#001965"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ImgAppSetupSuccess',
}
</script>
