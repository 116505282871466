<template>
  <div>
    <v-form
      v-if="currentStep === activateSteps.INPUT_SETTINGS"
      v-model="formValid"
      ref="form"
    >
      <DialogCard
        @closeDialog="resetAndCloseDialog"
        :loading="state === loadingState.LOADING"
      >
        <template #title>Set up the app for patient {{ patientNo }}</template>

        <template #default>
          <v-row>
            <v-col sm="5">
              <FormLabel> Select language for patient app </FormLabel>
              <v-autocomplete
                :items="appLanguageOptionsSorted"
                item-text="name"
                item-value="languageTag"
                v-model="appLanguage"
                outlined
                placeholder="Please select"
                :rules="appLanguageRules"
                data-testid="app-language"
                class="rounded-lg"
                @change="addAuditEntry('APP_SETTINGS:LANGUAGE', $event)"
                :menu-props="{
                  maxHeight: 400,
                }"
              />
            </v-col>

            <v-col sm="7">
              <FormLabel>
                Select the time format for the patient app
              </FormLabel>

              <v-radio-group
                v-model="appTimeFormat"
                :rules="appTimeFormatRules"
                data-testid="select-app-time-format"
                class="boxed-radio-group"
                @change="addAuditEntry('APP_SETTINGS:TIME_FORMAT', $event)"
                ><span
                  v-for="(option, index) in appTimeFormatOptions"
                  :key="index"
                  class="radio-option"
                  :class="{
                    selected: appTimeFormat === option.value,
                  }"
                >
                  <v-radio
                    :label="option.text"
                    :value="option.value"
                    :data-testid="`app-time-format-option-${option.value}`"
                  />
                </span>
              </v-radio-group>
            </v-col>
          </v-row>
        </template>
        <template #actions>
          <v-btn
            rounded
            depressed
            class="mr-4 elevation-1 btn-cancel"
            color="default"
            @click="resetAndCloseDialog"
            >Cancel</v-btn
          >
          <v-btn
            rounded
            depressed
            class="elevation-1 btn-close"
            color="secondary"
            :disabled="!formValid"
            @click.prevent="callApiCreateApp"
            >Continue</v-btn
          >
        </template>
      </DialogCard>
    </v-form>

    <DialogCard v-else-if="showQrCode" @closeDialog="resetAndCloseDialog">
      <template #title>Configure patient {{ patientNo }} ePID app</template>
      <template #default>
        <AppQrCode
          :loading="currentStep === activateSteps.LOADING_QR"
          :data-for-qr-code="dataForQrCode"
        />
        <v-alert v-if="apiError" type="error" dense text class="my-2">{{
          apiError
        }}</v-alert>
      </template>
      <template #actions>
        <v-btn
          rounded
          depressed
          class="mr-4 elevation-1 btn-cancel"
          color="default"
          @click="resetAndCloseDialog"
          >Cancel</v-btn
        >
      </template>
    </DialogCard>

    <DialogCard
      v-else-if="currentStep === activateSteps.DONE"
      @closeDialog="resetAndCloseDialog"
    >
      <template #title>App activation successful</template>
      <template #default>
        <v-row>
          <v-spacer></v-spacer>
          <ImgAppSetupSuccess />
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <h4 class="mt-4">Success!</h4>
            The patient app and the<br />
            ePID Dashboard are connected!
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <v-btn
          rounded
          depressed
          class="elevation-1 btn-close"
          color="secondary"
          @click.prevent="resetAndCloseDialog"
          >Close</v-btn
        >
      </template>
    </DialogCard>
  </div>
</template>

<script>
import service from '@/services/app-service'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import loadingState from '@/constants/loadingState'
import appTimeFormatOptions from '@/constants/appTimeFormatOptions'
import trialLanguagesMixin from '../trialLanguagesMixin'
import DialogCard from '@/components/DialogCard'
import FormLabel from '../add-patient/shared/FormLabel'
import ImgAppSetupSuccess from '../../images/ImgAppSetupSuccess'
import AuditLog from '@/utils/audit/AuditLog'

const activateSteps = {
  INITIAL: 1,
  INPUT_SETTINGS: 2,
  LOADING_QR: 3,
  QR_LOADED: 4,
  LOAD_ERRORED: 5,
  WAIT_FOR_DEVICE: 6,
  DONE: 7,
}
Object.freeze(activateSteps)

export default {
  name: 'ResumeAppSteps',
  mixins: [trialLanguagesMixin],
  components: {
    ImgAppSetupSuccess,
    FormLabel,
    DialogCard,
    AppQrCode: () =>
      import('@/components/patient/add-patient/init-app/AppQrCode'),
  },
  props: {
    patientNo: { type: String, required: true },
    isRetry: { type: Boolean, required: true },
  },
  data() {
    return {
      formValid: false,
      appLanguage: null,
      appTimeFormat: null,
      dataForQrCode: null,
      appTimeFormatOptions,
      appLanguageRules: [v => !!v || 'App language is required'],
      appTimeFormatRules: [v => !!v || 'Patient sex is required'],
      activateSteps,
      currentStep: this.isRetry
        ? activateSteps.LOADING_QR
        : activateSteps.INPUT_SETTINGS,
      loadingState,
      state: loadingState.INITIAL,
      apiError: null,
      auditLog: new AuditLog(),
    }
  },
  computed: {
    showQrCode() {
      return (
        this.currentStep === activateSteps.QR_LOADED ||
        this.currentStep === activateSteps.LOADING_QR
      )
    },
  },
  methods: {
    addAuditEntry(fieldName, value) {
      this.auditLog.log(fieldName, value)
    },
    callApiCreateApp() {
      this.currentStep = activateSteps.LOADING_QR
      this.state = loadingState.LOADING
      this.apiError = null

      const data = {
        patientNumber: this.patientNo,
        languageTag: this.appLanguage,
        clockNotation: this.appTimeFormat,
        currentClientTime: dateTimeWithTimeZone(),
        auditEntries: this.auditLog.getAuditEntries(),
      }

      service
        .createApp(data)
        .then(response => {
          this.dataForQrCode = JSON.stringify(response)
          this.currentStep = activateSteps.QR_LOADED
        })
        .catch(e => {
          this.state = loadingState.LOAD_ERRORED
          this.apiError = e
        })
    },
    callApiGetPendingApp() {
      service
        .getPendingApp(this.patientNo)
        .then(response => {
          this.dataForQrCode = JSON.stringify(response)
          this.currentStep = activateSteps.QR_LOADED
        })
        .catch(e => {
          this.state = loadingState.LOAD_ERRORED
          this.apiError = e
        })
    },
    resetForm() {
      this.appLanguage = null
      this.appTimeFormat = null
      this.currentStep = activateSteps.INITIAL
      this.state = loadingState.INITIAL
      if (this.$refs.form) this.$refs.form.reset()

      this.apiError = null
      this.dataForQrCode = null
      this.auditLog = new AuditLog()
    },
    resetAndCloseDialog() {
      this.$emit('close-dialog')
      this.resetForm()
    },
  },
}
</script>
